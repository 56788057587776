<template>
  <div class="shop-list">
    <section class="table-search">
      <div class="search-L">
        <el-select v-model="tableParams.merchantId" placeholder="请选择商户" @change="onMerchantSel" clearable class="mr20">
          <el-option v-for="item in commercialList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId">
          </el-option>
        </el-select>
        <el-select v-model="tableParams.shopId" placeholder="请选择店铺" clearable @change="onShopSel">
          <el-option v-for="item in tableShopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增服务内容</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA',
      color: '#000000',
    }">
      <el-table-column label="商户" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.merchantName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店铺" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.shopName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务内容名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.serviceName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="位置总数" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)"
            v-has="'commercial_service_edit'">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)"
            v-has="'commercial_service_del'">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="editShow" :title="editTitle" center :close-on-click-modal="false" width="40%"
      custom-class="add-dialog">
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 商户列表 -->
        <el-form-item label="所属商户" label-width="120px" prop="merchantId">
          <el-select v-model="params.merchantId" placeholder="请选择所属商户" filterable @change="onMerchant()"
            :disabled="params.serviceId != ''">
            <el-option v-for="item in commercialList" :key="item.merchantId" :label="item.merchantName"
              :value="item.merchantId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 店铺列表 -->
        <el-form-item label="所属店铺" label-width="120px" prop="shopId">
          <el-select v-model="params.shopId" placeholder="请选择所属店铺" @change="onShop()"
            :disabled="params.serviceId != ''">
            <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 服务名称 -->
        <el-form-item label="服务内容名称" label-width="120px" prop="serviceName">
          <el-input v-model.trim="params.serviceName" autocomplete="off" maxlength="8"
            placeholder="请填写服务内容名称"></el-input>
        </el-form-item>
        <!--服务类型-->
        <el-form-item style="display: flex; align-items: center">
          <div style="display: flex; align-items: center" slot="label">
            <el-popover placement="top" width="800" trigger="click">
              <span>不同服务类型模版区别在于小程序端部分页面显示不同</span>
              <div class="templateList">
                <div class="templateItem">
                  <div class="txt">例：包厢类模版</div>
                  <img class="img" src="@/assets/images/service/type1.jpg" alt="" />
                </div>
                <div class="templateItem">
                  <div class="txt">台球类模版</div>
                  <img class="img" src="@/assets/images/service/type2.jpg" alt="" />
                </div>
                <div class="templateItem">
                  <div class="txt">餐桌类模版（餐桌类服务内容服务及位置列表不在小程序端显示）</div>
                  <img class="img" src="@/assets/images/service/type3.jpg" alt="" />
                </div>
              </div>
              <i class="el-icon-question" slot="reference" style="margin-right: 0.1rem"></i>
            </el-popover>
            <span>服务类型</span>
          </div>
          <el-radio-group v-model="params.serviceTemplateType" style="display: flex;align-items: center;"
            @input="radioChange">
            <el-radio :label="1">包厢类</el-radio>
            <el-radio :label="2">台球类</el-radio>
            <el-radio :label="3">餐桌类</el-radio>
            <el-radio :label="4">仓储类</el-radio>
            <el-radio :label="5">工位类</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 服务类型模板 -->

        <el-row style="width: 100%;" v-if="serviceOpt.length>0">
          <el-col :span="16">
            <el-form-item label="服务类型模板" label-width="120px" prop="templateId" >
              <el-select v-model="params.templateId" placeholder="请选择服务类型模板" @change="templateChange">
                <el-option v-for="item in serviceOpt" :key="item.templateId" :label="item.templateName"
                  :value="item.templateId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-link type="primary" :underline="false" @click="getServiceModelDetail">模板内容</el-link>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false" style="margin-right:40px;">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')" :disabled="serviceOpt.length==0">保存</el-button>
        <div style="color: red; font-size: 12px; margin-top: 10px; text-align: center">tips:新增后商户、店铺不可修改，请谨慎操作</div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showModel" :title="'模板内容'" :close-on-click-modal="false" width="40%" center>
      <el-form :model="serviceForm" ref="serviceForm" label-width="200px">
        <el-form-item label="模板名称" prop="templateName">
          <el-input v-model="serviceForm.templateName" disabled></el-input>
        </el-form-item>
        <el-form-item label="单位单价">
          <el-select v-model="serviceForm.pricingType" disabled>
            <el-option v-for="item in ObjToOpt(priceTypeObj)" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单默认取消时间" prop="orderCancelNum">
          <el-input disabled v-model="serviceForm.orderCancelNum" class="input-with-select">
            <el-select v-model="serviceForm.orderCancelUnit" slot="append" disabled>
              <el-option v-for="item in ObjToOpt(timeUnit)" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="默认保洁时间" prop="cleanNum">
          <el-input v-model="serviceForm.cleanNum" class="input-with-select" disabled>
            <el-select v-model="serviceForm.cleanUnit" slot="append" disabled>
              <el-option v-for="item in ObjToOpt(timeUnit)" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="订单押金" prop="depositNum">
          <el-input v-model="serviceForm.depositNum" class="input-with-select" disabled>
            <el-select v-model="serviceForm.depositUnit" slot="append" disabled>
              <el-option v-for="item in ObjToOpt(depositUnit)" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>

      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getServiceList,
  getServiceEdit,
  getServiceDel,
} from "@/api/commercial/service";
import { serviceList, serviceInfo } from '@/api/setting/service.js'
import { getAutoCommercialList } from "@/api/commercial/commercial";
import { getIdShopList } from "@/api/commercial/shop";
import { priceTypeObj, timeUnit, depositUnit } from "@/db/objs";
import { ObjToOpt } from "@/utils/utils";
import { rules } from "@/db/rules";
import { merchantStatusObj } from "@/db/objs";
export default {
  data() {
    return {
      rules,
      priceTypeObj,
      ObjToOpt,
      timeUnit,
      depositUnit,
      merchantStatusObj,
      commercialList: [], // 商户列表
      tableShopList: [], //店铺列表
      // 列表
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      shopList: [], //店铺列表
      params: {
        serviceId: "", //服务ID
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        serviceName: "", //服务名称
        serviceTemplateType: 1, // 服务类型
      },
      servieOptParams: {
        serviceTemplateType: 1,
      },
      serviceForm: {},
      showModel: false,//服务模板内容弹窗
      serviceOpt: []
    };
  },
  mounted() {
    this.getAutoCommercialList(); // 【请求】商户下拉列表

  },
  methods: {
    // 【请求】列表
    getServiceList() {
      let data = this.tableParams;
      getServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】商户下拉列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        if (res.isSuccess == "yes") {
          this.commercialList = res.data;
          if (res.data && res.data.length > 0) {
            this.tableParams.merchantId = res.data[0].merchantId;
            this.getTableIdShopList();
          }
        }
      });
    },

    // 【请求】店铺列表
    getIdShopList() {
      let data = {
        merchantId: this.params.merchantId,
      };
      getIdShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.shopList = res.data;
        }
      });
    },

    // 【请求】新增编辑
    getServiceEdit() {
      let data = this.params;
      getServiceEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          if (data.serviceId) {
            this.getServiceList();
          } else {
            this.reload();
          }
        }
      });
    },

    // 【请求】筛选商户店铺列表
    getTableIdShopList() {
      let data = {
        merchantId: this.tableParams.merchantId,
      };
      getIdShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableShopList = res.data;
          if (res.data.length > 0) {
            this.tableParams.shopId = res.data[0].shopId;
          }
          this.getServiceList(); // 【请求】列表
        }
      });
    },

    // 【监听】筛选商户
    onMerchantSel() {
      this.tableParams.shopId = "";
      if (this.tableParams.merchantId) {
        this.getTableIdShopList();
      } else {
        this.tableShopList = [];
      }
    },

    // 【监听】筛选店铺
    onShopSel() {
      this.$forceUpdate();
      this.reload();
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getServiceList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getServiceList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】弹框商户选择
    onMerchant() {
      this.getIdShopList();
      this.params.shopId = "";
    },

    // 【监听】弹框店铺选择
    onShop() {
      this.$forceUpdate();
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增服务";
      this.params = {
        serviceId: "", //服务ID
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        serviceName: "", //服务名称
        serviceTemplateType: 1, // 服务类型
      };
      this.servieOptParams.serviceTemplateType = 1;
      this.getServiceModelList()
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑服务";
      this.params = JSON.parse(JSON.stringify(row));
      this.servieOptParams.serviceTemplateType = row.serviceTemplateType;
      this.getServiceModelList()
      this.getIdShopList();
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getServiceEdit();
        } else {
          return false;
        }
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = {
        serviceId: row.serviceId,
      };
      this.$confirm("你确定要删除该服务内容吗?", "删除服务内容", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getServiceDel(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getServiceList();
          }
        });
      });
    },
    // 获取服务模板
    getServiceModelList() {
      // 每次获取到列表之后服务类型模板的值默认第一项
      serviceList(this.servieOptParams).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data.length > 0) {
            this.serviceOpt = res.data;
            this.params.templateId = res.data[0].templateId;
          } else {
            this.$message({
              type: "warning",
              message: "暂无服务类型模板",
            })
            this.serviceOpt = [];
            this.params.templateId = ''
          }

        }
      });
    },
    // 单选变动时，对应待选服务模板配置项也需要更新
    radioChange(val) {
      this.servieOptParams.serviceTemplateType = val;
      this.getServiceModelList()
    },
    // 选择服务模板类型
    templateChange(val) {
      this.params.templateId = val;
      this.reload()
    },
    // 查询服务类型模板详情
    getServiceModelDetail() {
      this.showModel = true
      serviceInfo({ templateId: this.params.templateId }).then(res => {
        this.serviceForm = res.data;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.shop-list {
  padding: 0.15rem;
  box-sizing: border-box;

  ::v-deep.input-with-select .el-input-group__append {
    background-color: #fff;

    .el-select .el-input {
      width: 90px;
    }
  }

  ::v-deep .el-input {
    width: 110%;
  }

  ::v-deep .el-input-group {
    width: 110%;
  }

  // ::v-deep .el-form-item__content{
  //   width: 100%;
  // }
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.templateList {
  display: flex;
  justify-content: space-around;

  margin-top: 20px;

  .templateItem {
    width: 250px;

    .txt {
      height: 30px;
    }

    .img {
      width: 250px;
      height: 320px;
      margin-top: 20px;
    }
  }
}
</style>